.Czat {
  padding: 0.5em;
  width: 500px;
}
.wiadomosci {
  display: flex;
  flex-direction: column-reverse;
  max-height: 300px;
  overflow: auto;
}
.profilowe {
  border: 1px solid black;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin: 0;
  padding: 0;
}
.profContainer {
  width: 56px;
  height: 24px;
}
.linia {
  /* border-top: 1px solid rgb(230, 230, 230); */
  padding-top: 0.1em;
}
.dane {
  text-align: center;
  width: 100%;
  margin: auto;
  color: gray;
  font-size: smaller;
}
.nazwaWys {
  margin-top: -6px;
  font-weight: bold;
}
