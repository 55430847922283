.Instrukcje > table > thead > tr > th,
.Instrukcje > table > tbody > tr > td {
	width: 50% !important;
}
.video {
	width: 100% !important;
	max-width: 100%;
	min-height: fit-content;
}
.Instrukcje > table > thead > tr > th {
	background-color: #c4c8cb;
}
