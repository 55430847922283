.FileSystem {
	padding: 1em;
	width: 100%;
	height: 100%;
}
.uploader {
	width: 100%;
	height: 50vh;
	overflow: auto;
}
.fsDelButton {
	color: red;
}
.path > * {
	/* margin: 0px; */
	padding: 0px;
}
.path {
	padding-left: 1em;
	padding-bottom: 0.5em;
}
.linkBreadcrumb > * {
	text-decoration: none;
	color: inherit;
}
