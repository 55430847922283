.SortIndicator {
	display: inline;
	float: right;
}
.sortContainer {
	width: 10px;
	display: grid;
	grid-template-rows: 50% 50%;
	height: 14px;
}
.SortIndicator > span {
	font-size: x-small;
}
.SortIndicator > *:first-child {
	/* position: absolute; */
	transform: translate(0px, 0px);
}
