.dangerAcc button {
	background-color: var(--bs-danger-border-subtle) !important;
	color: #721c24;
}
.dangerAcc .accordion-body,
.dangerAcc .accordion-collapse {
	background-color: rgba(var(--bs-danger-rgb), 0.1) !important;
}
.dangerAcc {
	border-color: var(--bs-danger-border-subtle);
}
.warningAcc button {
	background-color: var(--bs-warning-bg-subtle) !important;
	color: #856404;
}
.warningAcc {
	border-color: var(--bs-warning-border-subtle);
}
.warningAcc .accordion-body,
.warningAcc .accordion-collapse {
	background-color: rgba(var(--bs-warning-rgb), 0.05) !important;
}
