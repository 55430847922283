/* Assuming the parent element has position: relative */
.Footer {
	/* position: absolute; */
	bottom: 0; /* Align to the bottom of the parent */
	left: 0; /* Align to the left edge */
	right: 0; /* Align to the right edge */
	background-color: #efecec;
	box-shadow: 0px 0px 5px gray;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -1rem; /* Adjust based on the parent's padding */
	margin-right: -1rem; /* Adjust based on the parent's padding */
	/* If you want to ignore the horizontal padding completely */
	width: calc(
		100% + 4rem
	); /* Extend the width to ignore padding (adjust for actual padding size) */
}
.FooterFiller {
	box-shadow: -5px 0px 5px gray;
	/* position: absolute; */
	bottom: 0; /* Align to the bottom of the parent */
	left: 0; /* Align to the left edge */
	right: 0; /* Align to the right edge */
	background-color: #efecec;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -1rem; /* Adjust based on the parent's padding */
	margin-top: -3rem;
	/* If you want to ignore the horizontal padding completely */
	width: calc(
		100% + 4rem
	); /* Extend the width to ignore padding (adjust for actual padding size) */
}
.FooterFiller2 {
	/* box-shadow: -5px 0px 5px gray; */
	/* box-shadow: 4px -1px 4px gray; */
	/* position: absolute; */
	bottom: 0; /* Align to the bottom of the parent */
	left: 0; /* Align to the left edge */
	right: 0; /* Align to the right edge */
	background-color: #efecec;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto; /* Adjust based on the parent's padding */
	margin-top: -3rem;
	/* If you want to ignore the horizontal padding completely */
	width: calc(
		4rem
	); /* Extend the width to ignore padding (adjust for actual padding size) */
}
