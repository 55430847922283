body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
	width: 26px;
	border-radius: 13px;
	background-clip: padding-box;
	border: 10px solid transparent;
	color: rgb(202, 202, 202);
}

*::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 0 10px;
}
.tab-content,
.tab-pane {
	height: 100%;
	width: 100%;
}
div {
	max-width: 100%;
	max-height: 100%;
}
div > div > div > div[role="listbox"] {
	z-index: 1000;
	background-color: white;
	box-shadow: 2px 2px 3px hsla(0, 0%, 0%, 0.2);
	border-radius: 4px;
}
div > div > div[role="listbox"] {
	z-index: 100;
	background-color: white;
	box-shadow: 2px 2px 3px hsla(0, 0%, 0%, 0.2);
	border-radius: 4px;
}
div > div[role="listbox"] {
	z-index: 100;
	background-color: white;
	box-shadow: 2px 2px 3px hsla(0, 0%, 0%, 0.2);
	border-radius: 4px;
}
div > div > div > div[role="listbox"] > div:not(:first-child) {
	border-top: 1px solid hsla(0, 0%, 0%, 0.1);
	/* box-shadow: 2px 2px 3px hsla(0, 0%, 0%, 0.2); */
}
.nav-item {
	z-index: 1;
}
form .btn {
	margin-top: 0.5em;
}
.btn {
	margin-right: 0.5em;
}
*:not(.material-symbols-outlined) {
	font-family: "Helvetica", serif !important;
}
:root {
	--bs-border-color: #00ae53 !important;
}
:root input {
	--bs-border-color: #dee2e6 !important;
}
