.tableHeader > th {
	background-color: var(--bs-secondary-border-subtle);
}
.czesc > th {
	font-weight: normal;
}
/* .czesc > th:first-child {
  background-color: white;
  --bs-table-bg-state: var(--bs-table-bg-state);
} */
