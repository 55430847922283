body,
html,
main {
	width: 100%;
	height: 100vh;
	overflow: auto;
}
.layout {
	/* padding: 1em; */
	display: grid;
	grid-template: calc(116px) 1fr / 1fr;
}
.page {
	width: 100%;
	height: 100%;
	margin: auto;
	overflow: auto;
}
