.Kalendarz > table > tbody > tr > th {
	width: 100px;
	font-size: small;
}
.Kalendarz > table > tbody > tr {
	height: 60px; /* Set a consistent height for all rows (adjust as needed) */
	max-height: 60px; /* Set a consistent height for all rows (adjust as needed) */
	overflow: hidden;
}
@media (max-width: 768px) {
	.Kalendarz {
		max-width: 75%;
		justify-content: center;
	}
}
.kratka {
	display: flex;
	flex-direction: column;
	height: 100%;
}
#weekdays {
	height: 10px;
	text-align: center;
}
.Kalendarz #weekdays > th {
	max-height: 10px !important;
	height: 10px !important;
	overflow: hidden; /* Hide any overflowing content */
	line-height: 10px; /* Ensure content inside follows the height */
}
.wydarzenia {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 60px;
	height: 60px;
	text-wrap: nowrap;
	overflow-y: auto; /* Enable vertical scrolling if needed */
	overflow-x: hidden; /* Disable horizontal scrolling */
	align-content: flex-start; /* Align items to the start of the flex container */
	justify-content: flex-start; /* Align items to the left */
	flex-grow: 1; /* Ensure .wydarzenia grows to fill the available space */
}
/* Optional: Remove last item margin if needed */
.wydarzenia > div:nth-child(4n) {
	margin-right: 0; /* Prevent extra space after the last item in each row */
}

.wydarzenia::-webkit-scrollbar {
	width: 4px; /* Width of the scrollbar */
}

.wydarzenia::-webkit-scrollbar-thumb {
	background: #888; /* Color of the scrollbar thumb */
	border-radius: 10px; /* Rounded corners for the scrollbar */
}

.wydarzenia::-webkit-scrollbar-thumb:hover {
	background: #555; /* Darker color on hover */
}

.wydarzenie {
	text-align: left !important;
	padding: 0;
	border: none;
	margin: 0;
	width: 100%;
	height: 18px;
	border-radius: 3px;
	margin-top: 0px;
	margin-right: 2px;
	border: 1px solid rgba(0, 0, 0, 0.288);
}
.dodajWyd {
	width: 18px;
}
/* .serwis {
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(243, 255, 70, 0.993),
		rgba(252, 185, 0, 0.664) 2px,
		transparent 2px,
		transparent 4px
	) !important;
	background-size: 20px 20px !important;
	border: 1px solid rgb(255, 217, 0);
} */
/* .zalecenie {
	background-image: repeating-linear-gradient(
		223deg,
		rgba(255, 255, 255, 0.2),
		rgba(0, 0, 0, 0.1) 1px,
		transparent 1px,
		transparent 1px
	) !important;
	background-size: 20px !important;
	border: 1px solid rgba(0, 0, 0, 0);
} */
.historyczny {
	background-image: repeating-linear-gradient(
		223deg,
		rgba(0, 0, 0, 0),
		rgb(110, 110, 110) 0px,
		transparent 10px,
		transparent 10px
	) !important;
}
.biologiczny {
	background-color: rgb(196, 252, 122) !important;
}
.kogeneracji {
	background-color: rgb(101, 196, 240) !important;
}
.technologiczny {
	background-color: rgb(245, 223, 101) !important;
}
.badanief {
	background-color: rgb(215, 255, 205) !important;
	background-color: rgb(215, 255, 205) !important;
	background-image: repeating-linear-gradient(
		223deg,
		rgba(255, 102, 0, 0.452),
		rgba(255, 255, 255, 0) 1px,
		transparent 1px,
		transparent 1px
	) !important;
}
.badaniep {
	background-color: rgb(215, 255, 205) !important;
	background-color: rgb(215, 255, 205) !important;
	background-image: repeating-linear-gradient(
		223deg,
		rgba(0, 16, 247, 0.226),
		rgba(255, 255, 255, 0) 1px,
		transparent 1px,
		transparent 1px
	) !important;
}
.admin {
	background-color: blueviolet !important;
	-webkit-box-shadow: 0px 0px 4px 0px rgba(110, 40, 210, 1);
	-moz-box-shadow: 0px 0px 4px 0px rgba(110, 40, 210, 1);
	box-shadow: 0px 0px 4px 0px rgba(110, 40, 210, 1);
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 10, 235, 0.979),
		rgb(253, 4, 170) 1px,
		transparent 1px,
		transparent 4px
	);
	background-size: 2px 2px !important;
}
.custom-modal-width {
	max-width: 1100px;
}
.custom-modal-width .modal-dialog {
	max-width: 1100px; /* Set a max width if desired */
}
