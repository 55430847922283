.Panel {
	padding-top: 0rem;
	width: calc(100%);
	padding: 0px 1rem;
	margin-top: 0.5rem;
}

.nav-link {
	color: black;
	padding-left: 0;
	padding-right: 0;
	padding-top: 8px; /* Ensure consistent top padding */
	margin-bottom: -1px !important; /* Align the bottom */
	border-bottom: none !important; /* Ensure no border on link */
	height: 42px; /* Ensure consistent height with NavDropdown */
}
.nav-link:hover {
	color: black;
}
.nav-link.active {
	text-decoration: underline;
	text-decoration-color: #0e9ed9;
}
.nav-item .nav-link {
	border-bottom: none !important;
}
.nav-link * {
	z-index: 50;
	position: relative;
}
.nav-item > a.nav-link:has(.dropdown) {
	padding: 0px !important;
	height: 100%;
	position: relative;
}
.dropdown-toggle.nav-link {
	border: none !important;
	padding-top: 8px;
	margin-bottom: -1px !important;
	height: 42px;
}
.dropdown-toggle {
	width: 100%;
}
.nav-item > .dropdown-menu {
	min-width: calc(100% + 2px) !important;
	border-top: none;
	margin-left: -1px;
	margin-top: -3px;
	padding: 0px 6px 4px 6px;
	border-top: 1px solid var(--bs-border-color);
}
.nav-item > .dropdown-menu > a {
	height: fit-content;
	max-width: 100%;
	text-wrap: wrap;
}
.nav-item > .dropdown-menu > a:not(:first-child) {
	border-top: 1px solid var(--bs-border-color);
}
div[role="tablist"] {
	position: sticky;
	top: 0;
	background: white;
	z-index: 10;
}
