.Header {
	position: sticky;
	width: 100%;
	padding-top: 0.5rem;
	height: 116px;
	background-color: #efecec;
	box-shadow: 0px 0px 5px gray;
	z-index: 100;
}
@media (max-width: 768px) {
	.buttonsMenu {
		display: none;
		height: 0px;
		width: 0px;
	}
	.buttonsMenu > * {
		display: none;
		height: 0px;
		width: 0px;
	}
	.col-md-4 {
		width: 100px;
	}
	.logo {
		height: 50px !important;
		display: block;
	}
	.col:has(.select) > div {
		/* margin-right: -6px; */
	}
	.select {
		z-index: 100;
		text-align: center;
		min-width: 20%;
		max-width: 50%;
		width: fit-content;
		margin: auto;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		font-size: 1rem !important;
	}
	.circles {
		width: 25px !important;
		height: 25px !important;
		object-fit: cover;
		margin: 5px;
		float: right;
		border-radius: 50%;
		border: 1px solid gray;
	}
	.circles > span {
		font-size: 15px !important;
		padding: 0px !important;
		margin-top: 4px !important;
	}
}
@media (max-width: 1138px) and (min-width: 768px) {
	.buttonsMenu > * {
		display: none;
		height: 0px;
		width: 0px;
	}
	.rightWing {
		display: grid;
		grid-template-columns: 1fr 96px;
	}
	.rightWing > *:nth-child(4) {
		grid-column: 2;
		grid-row: 1 / 3;
	}
	.rightWing *::after {
		display: none;
	}
	/* .buttonsMenu {
		display: none;
		height: 0px;
		width: 0px;
	}


	.logo {
		height: 50px !important;
		display: block;
	} */
	.col-md-4 {
		width: 35%;
	}
	.col:has(.select) > div {
		/* max-width: 50%; */
	}
	.col:has(.select) {
		max-width: 30%;
	}
	.select {
		z-index: 100;
		text-align: center;
		min-width: 20%;
		max-width: 50%;
		width: fit-content;
		margin: auto;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		font-size: 2rem !important;
	}
	.circles {
		width: 35px !important;
		height: 35px !important;
		object-fit: cover;
		margin: 5px;
		float: right;
		border-radius: 50%;
		border: 1px solid gray;
	}
	.profil {
		height: 86px !important;
		width: 86px !important;
		margin: 5px;
	}
	.circles > span {
		font-size: 20px !important;
		padding: 0px !important;
		margin-top: 6px !important;
	}
}
.logo {
	height: 100px;
	margin: 0px 1rem;
}
.select {
	z-index: 100;
	text-align: center;
	min-width: 50%;
	max-width: 100%;
	width: fit-content;
	margin: auto;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: xx-large;
}
.select * {
	z-index: 100;
}
.select > * > * {
	border: none;
	background-color: white;
	border-radius: 0.5rem;
}
.select > div {
	border: none;
	border-radius: 0.5rem;
}
.hrow,
.hrow > div {
	height: 100%;
}
.wyloguj {
	margin: auto;
	float: right;
	height: 100%;
}
.circles {
	width: 50px;
	height: 50px;
	object-fit: cover;
	margin: 5px;
	float: right;
	border-radius: 50%;
	border: 1px solid gray;
}
.circles > span {
	font-size: 30px;
	padding: 9px;
	margin: auto;
}
.profil {
	height: 86px;
	width: 86px;
	margin: 5px;
}
.dropdown-toggle {
	margin-top: auto;
	padding: 1px;
}
.dropdown-menu {
	max-height: max-content;
	max-width: max-content;
}
.dropdown-toggle {
	border: none;
}
.buttonsMenu {
	display: flex;
	flex-direction: column;
	height: 96px;
	justify-content: center;
	margin-top: 0.25rem;
}
.buttonsMenu > div > div > span {
	font-size: 28px;
}
.buttonsMenu > div > div {
	font-size: small;
	display: flex;
	align-items: center;
}
.leftWing {
	display: flex;
}
.chatNotifications {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px !important;
	padding: 0px !important;
	position: absolute;
	left: 63px;
	color: white;
	border: 1px solid gray;
	bottom: 42px;
	background-color: rgb(255, 72, 72);
	height: 20px;
	width: 20px;
	border-radius: 100%;
}
.notificationCount {
	margin-left: 0px;
	margin-bottom: 1px;
}
