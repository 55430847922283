.carousel-item > * > img {
	width: 100%;
	height: 484px;
	object-fit: cover;
}
.header > th:not(:first-child) {
	width: 200px;
}
.glownyContainer {
	width: 100%;
	margin-top: 1rem;
	grid-template-columns: 40% 1fr;
	display: grid;
}
@media (max-width: 768px) {
	.glownyContainer {
		grid-template-columns: 1fr; /* Single column */
		grid-template-rows: auto; /* Rows will adjust automatically based on content */
	}
}
.slajdlink {
	cursor: pointer;
}
.slajdOpis *,
.slajdOpis {
	color: white;
	text-decoration-color: white;
	text-shadow: 2px 2px 2px #666666;
}
.carousel-caption {
	bottom: -1rem;
}
.Glowny {
	--bs-border-color: #dee2e6;
}
.bioTab .tab-pane {
	font-size: 1.2rem;
	padding: 0rem 0.5rem;
	/* padding-bottom: 0px; */
	text-align: justify;
}
